<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true,right:'说明',real:false}"></v-header>
        </div>
        <div class="egg">
            <img src="../../assets/images/egg.png" alt="">
            <p>0</p>
            <button @click="goPage('payegg')">我的充值</button>
        </div>
        <div class="detail" @click="goPage('eggdetail')">
            <span>查看明细</span>
            <img src="../../assets/images/right_arrow.png" alt="">
        </div>
        <div class="desc">
            <p @click="goPage('eggexchange')">收益兑换太阳<img src="../../assets/images/right_arrow.png" alt=""></p>
            <p>【注】：太阳不可以退款、兑换、提现</p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: #eee;
        .egg{
            width: 94%;
            height: 164px;
            background-color: #fff;
            margin: 14px auto;
            text-align: center;
            padding: 10px 0;
            img{

            }
            p{
                margin: 0;
                padding: 0;
                height: 20px;
                color: #f4ea2a;
            }
            button{
                width: 90%;
                height: 40px;
                text-align: center;
                margin-top: 20px;
                border: none;
                color: #fff;
                background-color: #f4ea2a;
                border-radius: 6px;
                font-size: 18px;
            }
        }
        .detail{
            width: 100%;
            height: 40px;
            line-height: 40px;
            background-color: #fff;
            span{
                font-size: 16px;
                float: left;
                padding-left: 10px;
            }
            img{
                width: 12px;
                height: 12px;
                float: right;
                padding: 14px 10px 0 0;
            }
            &:active{
                background-color: #eee;
            }
        }
        .desc{
            width: 100%;
            height: 60px;
            position: fixed;
            bottom: 0;
            padding: 10px 0;
            p{
                padding: 0;
                margin: 0;
                font-size: 14px;
                text-align: center;
                img{
                    width: 12px;
                    height: 12px;
                }
                &:first-child{
                    margin: 0 auto;
                    width: 110px;
                    font-size: 16px;
                    padding: 6px 0;
                }
            }
        }
    }
</style>